import { Center, Text3D } from "@react-three/drei";

function HomeText() {
  return (
    <Center rotation={[-0.25, -0.25, 0]} position={[0, 50, -50]}>
      <Text3D
        font="/Inter_Bold.json"
        bevelSegments={24}
        curveSegments={64}
        bevelEnabled
        bevelSize={1}
        bevelThickness={0.5}
        lineHeight={0.6}
        letterSpacing={0.2}
        size={25}
        height={10}
      >
        {`simon\ndebbarma`}
        <meshNormalMaterial />
      </Text3D>
    </Center>
  );
}

export default HomeText;
