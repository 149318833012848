import Navigation from "../components/Navigation";
import "../styles/About.css";

export default function About() {
  return (
    <div className="p-20 about-page min-h-screen">
      <Navigation />
      <div className="grid grid-cols-12 gap-8 mt-8">
        <div className="col-span-8 text-4xl border-r-8 border-l-4 border-t-4 border-b-8 border-black p-24 bg-red-700 italic grid text-white">
          <div className="self-center">
            I am a{" "}
            <span className="font-bold underline">multidisciplinary</span>{" "}
            creator of products for the web, command line interface, and mobile
            devices like phones, tablets, and VR headsets. My expertise is in
            being{" "}
            <span className="font-bold chromatic">
              able to seamlessly switch between programming and design tasks
            </span>
            , producing high-quality work that aligns with business goals.{" "}
            <span className="underline">
              I have a strong ability to gather and integrate knowledge from
              various fields and use it to create cohesive solutions.
            </span>
          </div>
        </div>
        <div className="col-span-4 border-r-8 border-l-4 border-t-4 border-b-8 border-black p-16 bg-green-500 grid">
          <div className="self-center">
            I’m in the job market for a full-time position in the Bangkok
            Metropolitan area for the following roles. I’m open to remote work
            and consulting opportunities as well in{" "}
            <span className="underline">any time zone</span>.
            <ul className="list-none list-inside font-bold text-2xl my-4">
              <li>Design Technologist</li>
              <li>VR/AR Prototyper</li>
              <li>UX/UI Engineer</li>
              <li>Product Designer</li>
            </ul>
            <hr className="border-2 border-black my-4" />
            <div className="font-bold">
              Other roles I’m interested in include:
            </div>
            <ul className="list-inside py-2">
              <li>Full Stack Developer</li>
              <li>Frontend Engineer</li>
              <li>CLI Engineer</li>
              <li>Meme Creator</li>
            </ul>
          </div>
        </div>
        <div className="col-span-4">
          <img
            src="/img1.jpg"
            alt="about"
            className="border-r-8 border-l-4 border-t-4 border-b-8 border-black"
          />
        </div>
        <div className="col-span-4">
          <img
            src="/img2.jpg"
            alt="about"
            className="border-r-8 border-l-4 border-t-4 border-b-8 border-black"
          />
        </div>
        <div className="col-span-4">
          <img
            src="/img3.jpg"
            alt="about"
            className="border-r-8 border-l-4 border-t-4 border-b-8 border-black"
          />
        </div>
        <div className="col-span-12 text-4xl leading-smug border-r-8 border-l-4 border-t-4 border-b-8 border-black px-64 py-48 bg-black text-white">
          I’m a builder at heart who values putting the user experience above
          all else. While passionate about learning new technologies, I
          understand technology is a means to an end. I’m autonomous and
          communicative. I have strong opinions that are loosely held, and I
          challenge the status quo but I’m also quick to find common ground. I’m
          not afraid to get my hands dirty in an unfamiliar codebase to get the
          job done. I love moving fast. I know when to take shortcuts and when
          to dive deep. I am a team player and treat everyone with respect,
          especially our users and clients.
        </div>
      </div>
    </div>
  );
}
