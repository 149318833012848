import Navigation from "../components/Navigation";
import Chikadees from "../components/Chikadees";

export default function Code() {
  return (
    <>
      <div className="p-20 services-page min-h-screen bg-blue-500 space-y-8">
        <Navigation />
        <div className="grid grid-cols-4 border-4 border-black bg-neutral-100 rounded">
          <div className="col-span-4 text-2xl bg-black text-white p-48 px-96 space-y-16">
            <div>
              I am skilled in using JavaScript and Ruby to write software. These
              languages allow me to create interactive and functional websites
              and experiences for the web using tools like Node, React, and
              Three.js. I also use Ruby and its framework, Ruby on Rails, to
              prototype CRUD applications and write scripts to automate my
              systems. In addition, I enjoy building bots for Discord, Reddit,
              and Slack, as well as terminal apps using either JavaScript or
              Ruby. I have experience building SaaS applications and eCommerce
              stores using the MERN stack, but I prefer using BaaS providers
              such as Firebase or Supabase to handle the back-end development.
            </div>
            <div>
              My approach to developing software is to create a minimum viable
              product and get it in the hands of users as quickly as possible in
              order to gather real-world feedback and make iterative
              improvements. Before beginning the development process, I believe
              it is important to conduct research to understand the market and
              target user base in order to build software that meets their needs
              and meets the objectives of the project.
            </div>
          </div>
          <div className="col-span-4 col-start-1 grid grid-cols-2 space-y-4  border-y-4 border-black bg-neutral-100">
            <h2 className="text-6xl font-bold col-span-2 pt-8 px-8">Sonolog</h2>

            <div className="px-8 col-span-2 flex space-x-4">
              <div className="border-2 border-black bg-black text-white px-4 py-2 rounded-3xl">
                JavaScript
              </div>
              <div className="border-2 border-black bg-black text-white px-4 py-2 rounded-3xl">
                Next.js
              </div>
              <div className="border-2 border-black bg-black text-white px-4 py-2 rounded-3xl">
                Recoil
              </div>
              <div className="border-2 border-black bg-black text-white px-4 py-2 rounded-3xl">
                TailwindCSS
              </div>
              <div className="border-2 border-black bg-black text-white px-4 py-2 rounded-3xl">
                Spotify API
              </div>
            </div>
            <div className="col-span-2">
              <img src="/code/sonolog1.png" className="" />
            </div>
            <div className="col-span-2 text-2xl py-32 px-96">
              Sonolog was created to address the issue that when playlists on
              streaming platforms like Spotify are shared, the care and thought
              put into creating them is lost. Sonolog is a web app that allows
              users to create and share playlists, but also allows users to add
              notes to each song in the playlist. This way, when the playlist is
              shared with others, they can understand the reasoning and
              intention behind the song choices.
            </div>
            <div className="col-span-1 m-8 border-4 border-white">
              <img src="/code/sonolog2.png" className="" />
            </div>
            <div className="col-span-1 m-8 border-4 border-white">
              <img src="/code/sonolog3.png" className="" />
            </div>
            <div className="col-span-2 text-2xl pt-32 pb-4 px-96">
              Sonolog is written in Javascript using Next.js and React for the
              frontend. The app is styled using TailwindCSS. It uses Recoil for
              state management and Firebase for the backend. We also integrated
              Spotify's API to allow users to authenticate themselves and fetch
              their playlists.
            </div>
            <div className="col-span-2 text-2xl pb-32 pt-4 px-96">
              Sonolog is currently in Invite-Only Beta. If you would like to be
              a part of the beta, please send me a message.
            </div>
          </div>
          <div className="col-span-4 text-2xl py-64 px-96 bg-black text-white">
            Object Oriented Programming is a paradigm that I love working with.
            There are many ways to write software, but OOP is the one that I
            find the most intuitive and easy to work with. I've written software
            in many languages, but I've found that JavaScript and Ruby are the
            ones that I enjoy the most. Ruby is a great language for writing a
            lot of code quickly, but JavaScript is the language I prefer for
            building web applications. When I'm not writing software, I'm also a
            big fan of reading about software. I love reading about the history
            of software, and how it has evolved over the years. I also love
            reading about the philosophy behind software, and how we can build
            better software for the future.
          </div>
          <div className="col-span-4 grid grid-cols-2 border-y-4 border-black bg-neutral-100">
            <div className="col-span-1 grid grid-cols-2 p-16 space-y-4 border-r-2 border-black">
              <h2 className="text-6xl font-bold col-span-2 px-8">
                Outgrid.css
              </h2>
              <div
                className="px-8 col-span-2 flex space-x-4"
                style={{ height: "fit-content" }}
              >
                <div className="border-2 border-black bg-black text-white px-4 py-2 rounded-3xl">
                  CSS
                </div>
                <div className="border-2 border-black bg-black text-white px-4 py-2 rounded-3xl">
                  Sass
                </div>
                <div className="border-2 border-black bg-black text-white px-4 py-2 rounded-3xl">
                  Gulp
                </div>
              </div>
              <div className="p-1">
                <img src="/code/outgrid1.png" className="h-full" />
              </div>
              <div className="p-1">
                <img src="/code/outgrid2.png" className="h-full" />
              </div>
              <div className="text-xl col-span-2">
                Outgrid is a CSS framework written using Sass and Javascript. It
                is based on the CSS Grid Layout Module and is designed to be
                used with Tailwind CSS. Outgrid is a work in progress and is
                currently in alpha.
              </div>
            </div>
            <div className="col-span-1 grid grid-cols-2 p-16 space-y-4  bg-neutral-100 border-l-2 border-black">
              <h2 className="text-6xl font-bold col-span-2 px-8">
                Chikadees in CSS
              </h2>
              <div
                className="px-8 col-span-2 flex space-x-4"
                style={{ height: "fit-content" }}
              >
                <div className="border-2 border-black bg-black text-white px-4 py-2 rounded-3xl">
                  CSS
                </div>
              </div>
              <div className="col-span-2">
                <Chikadees />
              </div>
              <div className="text-xl col-span-2">
                This is an illustration of two chikadees, done completely in
                pure CSS. Feel free to right click the birds and inspect the
                element to see how it's done. I used a combination of CSS shapes
                and gradients to create the birds. I see a lot of potential in
                this form of art as it allows for web content to be responsive
                to the browser window size.
              </div>
            </div>
          </div>

          <div className="col-span-4 text-2xl py-64 px-96 bg-black text-white">
            Computers are excellent tools for people because they are good at
            many things that people are not. From a perspective which focuses on
            technology, humans are imprecise, illogical, disorganized, and make
            mistakes frequently. They are, however, excellent at forming habits
            and matching patterns, two things computers have a difficult time
            doing. For example, I write commands to be undoable whenever
            possible and when it is not possible, I make sure to inform the user
            that such is the case. I capitalize on a computer's strengths to
            make up for a users inherent weaknesses.
          </div>

          <div className="col-span-4 grid grid-cols-2 p-8 space-y-4 border-y-4 border-black bg-neutral-100">
            <h2 className="text-6xl col-span-2 font-bold px-8">eztv_scraper</h2>
            <div className="px-8 col-span-2 flex space-x-4">
              <div className="border-2 border-black bg-black text-white px-4 py-2 rounded-3xl">
                Ruby
              </div>
              <div className="border-2 border-black bg-black text-white px-4 py-2 rounded-3xl">
                Nokogiri
              </div>
            </div>
            <div className="col-span-1 p-4">
              <img src="/code/eztv1.png" className="h-full" />
            </div>
            <div className="col-span-1 p-4">
              <img src="/code/eztv2.png" className="h-full" />
            </div>
            <div className="col-span-2 text-2xl pt-16 pb-4 px-96">
              EZTV-Scraper looks for any selected show on eztv and saves magnet
              links of every single available episode into a JSON file. It also
              saves the episode name and the episode number. This is useful for
              people who want to download every episode of a show and don't want
              to go through the hassle of searching for each episode
              individually. This is a command line tool written in Ruby and uses
              the Nokogiri gem to scrape the EZTV website. It is currently in
              beta and is available on GitHub.
            </div>
            <div className="col-span-2 text-sm pb-16 pt-4 px-96">
              I do not condone piracy and this tool is only a proof of concept.
            </div>
          </div>

          <div className="col-span-4 text-2xl py-64 px-96 bg-black text-white">
            Good software development starts with building a clear understanding
            of the problem you want to solve. This lets you test many possible
            solutions and converge on a good approach. Development is
            accelerated by reusing the right open source code and cloud
            services, granting immediate access to established software systems
            and sophisticated new technology. The development cycle alternates
            between exploration and consolidation, quickly and messily
            progressing on new ideas, then focusing and simplifying to keep the
            complexity manageable. As the project moves forward, it gets tested
            with successively larger groups of people to eliminate increasingly
            uncommon problems. Launching is when the real work ramps up for a
            good development team: layers of automated systems should be built
            to handle issues quickly and prevent harm to actual users.
            Ultimately, while there are infinite intricacies to software
            development, understanding this process provides a basis to tackle
            the complexities of how to build good software.
          </div>
          {/* <div className="col-span-4 text-xl py-64 px-96 grid grid-cols-2"></div> */}
        </div>
      </div>
    </>
  );
}
