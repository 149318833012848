import Navigation from "../components/Navigation";

export default function Design() {
  return (
    <>
      <div className="p-20 services-page min-h-screen bg-green-600 space-y-8">
        <Navigation />
        <div className="grid grid-cols-4 border-4 border-black bg-neutral-100 rounded">
          <div className="col-span-4 text-2xl bg-black text-white p-48 px-96 space-y-16">
            <div>
              When I learnt to code, I had to learn how to design aesthetically.
              I had to learn how to think like a designer. Styling webpages and
              components was so much fun and I found the design process very
              interesting. Design is a skill that came very naturally to me. I
              was surprised to discover that there is a lot of thought and
              process that goes into this. Good design is not just about making
              things look pretty, it's about making them functional, it's about
              subtly guiding the user to do what they want to do, it's about
              making interfaces easy to use and understand.
            </div>
            <div className="col-span-1">
              <img src="/design/BBAC.png" />
            </div>
          </div>
          <div className="col-span-4 text-xl bg-white text-black p-48 px-96 space-y-8">
            <div className="text-8xl py-8">Methodology</div>
            <div className="">
              The core foundation of any good design is in the research. My
              design process begins with understanding the problem and the user.
              My preferred method of research is to use design sprints. It's a
              proven system to solve problems through design, prototyping and
              testing ideas with users. I have used this process to design a
              number of products and services. I have also used this process to
              design my own portfolio website.
            </div>
            <div className="">
              In this section, I will walk you through my design process and
              provide a birds eye view of how I use design sprints to solve
              design problems. Designing products and services is a very
              iterative process. I will be using examples from various projects
              to demonstrate it.
            </div>
            <div className="text-4xl">Stage 1: Understand</div>
            <div className="">
              The first stage of my sprint is to understand the problem and
              establish a shared understanding of the problem. This is done by
              conducting a number of interviews with the stakeholders and users.
              I start with a number of open ended questions to understand the
              problem and then move on to more specific questions to understand
              the users and their needs. I use the information gathered from
              these interviews to create a golden circle, experience map, and a
              user journey map.
            </div>
            <div className="text-4xl">Stage 2: Define</div>
            <div className="">
              The second stage is to define the problem on a more granular
              level. This is done by creating a map of the problem and how the
              user interacts with the problem. I prioritize understanding the
              central flow of the problem because knowing the product's ideal
              path helps focus my efforts on the most important parts of the
              product. I then create metrics to measure happiness, engagement,
              adoption, retention, and success.
            </div>
            <div className="text-4xl">Stage 3: Ideate</div>
            <div className="">
              The third stage is to ideate solutions rapidly. My preferred
              method is to use Crazy 8s. I doodle rough solutions, and try to
              come up with as many ideas as possible. The idea is to generate a
              wide variety of solutions and then narrow them down to the best
              ones. Then I create what's called a "solution sketch" where I
              figure out further details.
            </div>
            <div className="text-4xl">Stage 4: Prototype</div>
            <div className="">
              The fourth stage is to prototype the best solutions. I use either
              Figma, Adobe XD, or Lunacy to mockup my designs; and then use a
              frontend framework like React to build a prototype. I prefer using
              code as a medium to prototype because it allows me to version
              control my designs and test them with users.
            </div>

            <div className="col-span-1">
              <img src="/design/mockup1.png" />
            </div>
            <div className="text-4xl">Stage 5: Validate</div>
            <div className="">
              The fifth stage is to test the prototypes with users. The best
              ways are to use a usability test, a cognitive walkthrough, or a
              user interview. I use a combination of these methods to gather
              feedback to iterate on the designs.
            </div>
          </div>
          <div className="col-span-4 text-2xl bg-black text-white p-48 px-96 space-y-16">
            <div className="text-8xl py-8">Design Systems</div>
            <div>
              Design systems are a collection of reusable components, guided by
              clear standards, that can be assembled together to build any
              number of applications. They are a great way to create a
              consistent user experience across multiple products. They also
              help to create a common language between designers and developers.
              I have used design systems to create a number of products and
              services. I have also used design systems to create my own
              portfolio website.
            </div>
          </div>
          <div className="col-span-4 text-2xl bg-white text-black p-48 px-96 space-y-16">
            <div className="text-8xl py-8">Designing for XR</div>
            <div>
              XR is a collective term for all immersive technologies that allow
              us to enhance reality with the help of visual stimuli. It includes
              Virtual Reality (VR), Augmented Reality (AR), and Mixed Reality
              (MR). The XR industry is growing at a rapid pace and is expected
              to be worth $397.81 billion by 2026.
            </div>
            <div>
              Since we started designing for screens in the last century, we
              have been optimizing for comfort and usability only for 2D
              screens. But with XR, we are adding a new dimention to how
              information is displayed and interacted with by the user. We are
              in a completely new unknown territory and have to reimagine how to
              design for it.
            </div>
            <div>
              A personal goal is to be a leader in designing XR experiences in
              user interactions. The potential for this industry is massive and
              I really want to be a part of it. My ideal role in this current
              era would be prototyping applications for XR or researching UI/UX
              for XR.
            </div>
            <div>
              <div className="text-2xl font-bold py-4"></div>
              <div></div>
            </div>
          </div>
          <div className="col-span-4 text-2xl bg-black text-white p-48 px-96 space-y-16">
            <div className="col-span-1">
              <img src="/design/Krospatch.png" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
