import * as THREE from "three";
import { Suspense, useRef, useState } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import {
  CameraShake,
  Stars,
  Sky,
  MeshDistortMaterial,
  SpotLight,
} from "@react-three/drei";
import { Model } from "../models/SimonDance";

function Light() {
  const ref = useRef();
  useFrame((_) => (ref.current.rotation.x = _.clock.elapsedTime));
  return (
    <group ref={ref}>
      <rectAreaLight
        width={15}
        height={100}
        position={[30, 30, -10]}
        intensity={2}
        onUpdate={(self) => self.lookAt(0, 0, 0)}
      />
    </group>
  );
}

function Rig() {
  const [vec] = useState(() => new THREE.Vector3());
  const { camera, mouse } = useThree();
  useFrame(() => {
    camera.position.lerp(vec.set(mouse.x, 20 + mouse.y, 50), 1);
  });
  return (
    <CameraShake
      maxYaw={0.01}
      maxPitch={0.01}
      maxRoll={0.01}
      yawFrequency={0.5}
      pitchFrequency={0.5}
      rollFrequency={0.4}
    />
  );
}

function Dance() {
  return (
    <Suspense fallback={null}>
      <Rig />
      <spotLight position={[50, 50, -30]} intensity={0.5} castShadow />
      <pointLight position={[-10, 10, -10]} color="hotpink" intensity={0.5} />
      <pointLight position={[10, 10, 10]} color="purple" intensity={0.5} />
      <directionalLight position={[0, -5, 10]} color="white" intensity={0.1} />
      <ambientLight intensity={0.05} />
      <Light />
      {/* <fog attach="fog" args={["white", 100, 1000]} /> */}
      <Model scale={15} />
      <Sky
        distance={450000}
        sunPosition={[100, 10, 100]}
        inclination={20}
        azimuth={52}
        rayleigh={0.2}
      />
      <Stars
        radius={100}
        depth={50}
        count={2000}
        factor={10}
        saturation={2}
        fade
        speed={2}
      />
      <mesh receiveShadow position={[0, 0, 0]} rotation={[-Math.PI / 2, 0, 0]}>
        <boxGeometry args={[60, 60]} />
        <MeshDistortMaterial distort={0.75} speed={2.5} />
      </mesh>
    </Suspense>
  );
}

export default Dance;
