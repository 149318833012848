import Navigation from "../components/Navigation";
import "../styles/Portfolio.css";

export default function Portfolio() {
  return (
    <div className="p-20 portfolio-page min-h-screen">
      <Navigation />
    </div>
  );
}
