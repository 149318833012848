import { Link } from "react-router-dom";
import "../styles/Navigation.css";

export default function Navigation() {
  return (
    <div className="flex font-bold text-2xl border-4 bg-white border-black border-r-8 border-l-4 border-t-4 border-b-8 rounded px-8 py-4">
      <div className="space-x-10 flex-grow grid-flow-col h-full self-center ">
        <Link to="/" className="hover:underline">
          _home
        </Link>
        <Link to="/about" className="hover:underline ">
          _about
        </Link>
        <Link to="/design" className="hover:underline">
          _design
        </Link>
        <Link to="/code" className="hover:underline">
          _code
        </Link>
        <Link to="/visual" className="hover:underline">
          _visual
        </Link>
      </div>
      <div className="border-r-8 border-l-4 border-t-4 border-b-8 border-black bg-yellow-400 hover:bg-green-500 px-4 py-2 hover:underline rounded">
        <a href="mailto:simonpeterdebbarma@outlook.com">contact</a>
      </div>
    </div>
  );
}
