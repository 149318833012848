import React, { useRef } from "react";
import { Center, Text3D } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";

function IntroText() {
  const descriptors = React.useRef();

  useFrame(({ clock }) => {
    descriptors.current.rotation.x = Math.sin(clock.getElapsedTime()) * 0.05;
    descriptors.current.rotation.y = Math.cos(clock.getElapsedTime()) * 0.06;
  });

  return (
    <Center bottom position={[65, 15, -25]} ref={descriptors}>
      <Text3D
        bevelEnabled
        bevelSize={0.1}
        bevelThickness={0.1}
        color="#000000"
        letterSpacing={0.1}
        size={3.5}
        height={1.2}
        font="/Inter_Bold.json"
      >
        {`Software Engineer.\nProduct Designer.\nCreative Technologist.`}
        <meshNormalMaterial />
      </Text3D>
    </Center>
  );
}

export default IntroText;
