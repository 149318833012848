import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import App from "./pages/App";
import About from "./pages/About";
import Portfolio from "./pages/Portfolio";
import Code from "./portfolio/Code";
import Design from "./portfolio/Design";
import Visual from "./portfolio/Visual";
import F0F from "./pages/404";

import "./styles/index.css";

import "@fontsource/inter";
import "@fontsource/josefin-sans";
import "@fontsource/ibm-plex-sans";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="about" element={<About />} />
      <Route path="portfolio" element={<Portfolio />} />
      <Route path="code" element={<Code />} />
      <Route path="design" element={<Design />} />
      <Route path="visual" element={<Visual />} />
      <Route path="*" element={<F0F />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
