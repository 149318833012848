import Navigation from "../components/Navigation";

export default function Visual() {
  return (
    <div className="p-20 services-page min-h-screen bg-red-500 space-y-8">
      <Navigation />

      <div className="grid grid-cols-4 border-4 border-black bg-neutral-100 rounded">
        <div className="col-span-4 text-2xl bg-black text-white p-48 px-96 space-y-16">
          <div className="text-8xl py-8">Illustrations</div>
          {/* self-aligning grid of images */}
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-1">
              <img src="/design/illustration1.png" />
            </div>
            <div className="col-span-1">
              <img src="/design/illustration2.png" />
            </div>
            <div className="col-span-1">
              <img src="/design/illustration6.png" />
            </div>
            <div className="col-span-1">
              <img src="/design/illustration4.png" />
            </div>
            <div className="col-span-1">
              <img src="/design/illustration5.jpg" />
            </div>
            <div className="col-span-1">
              <img src="/design/illustration3.png" />
            </div>
            <div className="col-span-1">
              <img src="/design/illustration7.png" />
            </div>
            <div className="col-span-1">
              <img src="/design/illustration8.png" />
            </div>
            <div className="col-span-1">
              <img src="/design/illustration9.png" />
            </div>
          </div>
        </div>

        <div className="col-span-4 text-2xl bg-white text-black p-48 px-96 space-y-16">
          <div className="text-8xl py-8">Logo</div>
          <div className="col-span-1">
            <img src="/design/Krospatch.png" />
          </div>
        </div>

        <div className="col-span-4 text-2xl bg-black text-white p-48 px-96 space-y-16">
          <div className="text-8xl py-8">Film</div>
          <div></div>
        </div>
      </div>
    </div>
  );
}
