import { Canvas } from "@react-three/fiber";
import { Suspense } from "react";
import {
  EffectComposer,
  SSAO,
  Noise,
  ChromaticAberration,
} from "@react-three/postprocessing";
import { ContactShadows, Loader, OrbitControls } from "@react-three/drei";

import Bubbles from "../components/Bubbles";
import Dance from "../components/Dance";
import Directory from "../components/Directory";
import HomeText from "../components/HomeText";
import IntroText from "../components/IntroText";
import Warning from "../components/Warning";

import "../styles/App.css";

function App() {
  return (
    <>
      <Warning />
      <div className="App bg-black">
        <Canvas>
          {/* <OrbitControls /> */}
          <ContactShadows
            position={[0, -30, 0]}
            opacity={0.6}
            scale={130}
            blur={1}
            far={40}
            />
          <EffectComposer multisampling={0}>
            {/* <SSAO
              samples={31}
              radius={2}
              intensity={5}
              luminanceInfluence={0.1}
              color="green"
            /> */}
            <Noise opacity={0.15} />
            <ChromaticAberration offset={[0.0001, 0.0002]} />
          </EffectComposer>
          <Suspense fallback={null}>
            <Bubbles />
            <HomeText />
            <IntroText />
            <Dance />
          </Suspense>
        </Canvas>
        <Loader dataInterpolation={(p) => `Loading: ${p.toFixed(2)}%`} />
        <Directory />
      </div>
    </>
  );
}

export default App;
