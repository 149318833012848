import "../styles/Chikadees.css";

export default function Chikadees() {
  return (
    <div class="canvas">
      <div class="whitey-beak"></div>
      <div class="yellow-beak"></div>

      <div class="whitey-tail one"></div>
      <div class="whitey-tail two"></div>

      <div class="yellow-tail"></div>

      <div class="bird-body left">
        <div class="face-whitey"></div>
        <div class="eye whitey"></div>
        <div class="belly-whitey">
          <div class="chest-yellow"></div>
          <div class="feathers-whitey">
            <div class="feathers-whitey-stripes one"></div>
            <div class="feathers-whitey-stripes two"></div>
            <div class="feathers-whitey-stripes three"></div>
            <div class="feathers-whitey-stripes four"></div>
            <div class="feathers-whitey-stripes five"></div>
            <div class="feathers-whitey-stripes six"></div>
            <div class="feathers-whitey-stripes seven"></div>
          </div>
        </div>
      </div>

      <div class="yellow-feet"></div>

      <div class="branch"></div>

      <div class="feet-whitey-left">
        <div class="toe one"></div>
        <div class="toe-connectarch one"></div>
        <div class="toe two"></div>
        <div class="toe-connectarch two"></div>
        <div class="toe three"></div>
      </div>
      <div class="feet-whitey-right">
        <div class="toe four"></div>
        <div class="toe-connectarch three"></div>
        <div class="toe five"></div>
        <div class="toe-connectarch four"></div>
        <div class="toe six"></div>
      </div>
      <div class="bird-body right">
        <div class="face-yellow"></div>
        <div class="eye yellow"></div>
        <div class="belly-yellow"></div>
      </div>
      <div class="wing-bulge"></div>
      <div class="wings">
        <div class="wing-left">
          <div class="stripey seven"></div>
          <div class="stripey eight"></div>
          <div class="stripey nine"></div>
          <div class="stripey ten"></div>
          <div class="stripey eleven"></div>
          <div class="stripey tweleve"></div>
        </div>
        <div class="wing-right">
          <div class="stripey one"></div>
          <div class="stripey two"></div>
          <div class="stripey three"></div>
          <div class="stripey four"></div>
          <div class="stripey five"></div>
          <div class="stripey six"></div>
        </div>
      </div>
    </div>
  );
}
