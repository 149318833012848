import { useState } from "react";
import "../styles/Directory.css";

export default function Directory() {
  const [isHovering, setIsHovering] = useState(false);
  
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        pointerEvents: "none",
        width: "100%",
        height: "100%",
      }}
    >
      <a
        href="https://blog.simonpeterdebbarma.com/"
        style={{
          position: "absolute",
          bottom: 60,
          right: 60,
          fontSize: "12px",
          fontFamily: "IBM Plex Sans",
          fontStyle: "italic",
        }}
      >
        made with
        <br />
        love and javascript
      </a>
      <div
        style={{
          position: "absolute",
          top: 90,
          left: 90,
          fontSize: "50px",
          fontWeight: "bold",
          textAlign: "left",
          fontFamily: "Inter",
        }}
      >
        <a href="/about">
          <div className="directory-pages">about</div>
        </a>
        <br />
        <a href="/design">
          <div className="directory-pages">design</div>
        </a>
        <br />
        <a href="/code">
          <div className="directory-pages">code</div>
        </a>
        <br />
        <a href="/visual">
          <div className="directory-pages">visual</div>
        </a>
        <br />
      </div>
    </div>
  );
}
