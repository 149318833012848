import { MathUtils } from "three";
import { useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { Instances, Instance } from "@react-three/drei";

const particles = Array.from({ length: 1000 }, () => ({
  factor: MathUtils.randInt(20, 100),
  speed: MathUtils.randFloat(0.01, 1),
  xFactor: MathUtils.randFloatSpread(1000),
  yFactor: MathUtils.randFloatSpread(1000),
  zFactor: MathUtils.randFloatSpread(1000),
}));

export default function Bubbles() {
  const ref = useRef();
  useFrame(
    (state, delta) =>
      void (ref.current.rotation.y = MathUtils.damp(
        ref.current.rotation.y,
        (-state.mouse.x * Math.PI) / 16,
        1,
        delta
      ))
  );
  return (
    <Instances
      limit={particles.length}
      ref={ref}
      castShadow
      receiveShadow
      position={[0, 10, 0]}
    >
      <boxGeometry args={[5, 5, 5]} />
      <meshStandardMaterial roughness={0} metalness={0.2} color="#f0f0f0" />
      {particles.map((data, i) => (
        <Bubble key={i} {...data} />
      ))}
    </Instances>
  );
}

function Bubble({ factor, speed, xFactor, yFactor, zFactor }) {
  const ref = useRef();
  useFrame((state) => {
    const t = factor + state.clock.elapsedTime * (speed / 2);
    ref.current.scale.setScalar(Math.max(1.5, Math.cos(t) * 5));
    ref.current.position.set(
      Math.cos(t) +
        Math.sin(t * 1) / 100 +
        xFactor +
        Math.cos((t / 100) * factor) +
        (Math.sin(t * 1) * factor) / 100,
      Math.sin(t) +
        Math.cos(t * 2) / 100 +
        yFactor +
        Math.sin((t / 10) * factor) +
        (Math.cos(t * 2) * factor) / 100,
      Math.sin(t) +
        Math.cos(t * 2) / 100 +
        zFactor +
        Math.cos((t / 10) * factor) +
        (Math.sin(t * 3) * factor) / 100
    );
  });
  return <Instance ref={ref} />;
}
